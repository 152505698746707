<template>
    <div class="mobie-body">
        <div class="info_box" v-loading="checkAuth == -1">
            <div class="success_box" v-if="checkAuth == 1">
                <el-avatar :src="avatar"></el-avatar>
                <div style="margin-top: 8px">用户昵称：{{ username }}</div>
                <div style="margin-top: 16px">
                    授权账号，精细化分析视频发布数据，快去体验吧~
                </div>
            </div>
            <div class="error_box" v-if="checkAuth == 0">
                <div class="error_img">
                    <el-image
                        src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/error.png"
                    >
                        <div slot="placeholder" class="image-slot">
                            加载中<span class="dot">...</span>
                        </div>
                    </el-image>
                </div>
                <div class="" style="margin-top: 10px">授权失败</div>
            </div>
            <div class="btns">
                <el-button type="primary" @click="openUrl" size="small"
                    >授权其他账号</el-button
                >
            </div>
        </div>
        <!-- <div class="content">
            <div class="">
                <img src="@/assets/images/null_state/autook.png" alt="" />

                <div style="margin-top: 20px" v-if="checkAuth == 1">
                    <span v-if="checkAuth == 1">授权成功</span>
                    <span v-if="checkAuth == 2">授权失败</span>
                    <span v-if="checkAuth == 0">正在授权</span>
                    <span>
                    </span>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { setAuth, getCookiedLin } from "@/api/auth";
import QrCode from "../../node_modules/qrcode-decoder/dist/index";
export default {
    name: "success",

    data() {
        return {
            // 授权状态1成功0失败 -1等待
            checkAuth: -1,
            // 授权用户昵称
            username: "",
            // 授权用户头像
            avatar: "",
            // 授权链接
            auth_link: "",
        };
    },

    created() {
        this.checkAuth == 0;
        this.req = this.$route.query;
        let stateArr = this.req.state.split(",");
        console.log(stateArr);

        let type = stateArr[1];
        this.req.state = stateArr[0];
        // console.log(stateArr[2]);
        var groupIds;
        try {
            groupIds = stateArr[2].replaceAll("|", ",");
        } catch (error) {
            groupIds = false;
        }

        // groupIds = JSON.parse(groupIds);
        if (groupIds) {
            console.log("有绑定分组");
            this.req.groupId = groupIds;
        } else {
            console.log("没有绑定分组");
            this.req.groupId = "";
        }
        // console.log(groupIds);
        var url = "";
        if (type == 1) {
            // 抖音
            url = "/api/app/douyinresult/AuthDouYin";
        } else {
            // 快手
            url = "/api/app/accountdata/kwaiCallBack";
        }
        // console.log(this.req);
        setAuth(url, this.req).then((res) => {
            if (res.code == 200) {
                this.checkAuth = 1;
                if (type == 1) {
                    // 抖音返回结果
                    let data = res.data;
                    this.avatar = data.avatar;
                    this.username = data.nickname;
                    this.auth_link = data.authorizeUrl;
                } else {
                    // 快手返回结果
                    let data = res.data;
                    this.avatar = data.headPortraitUrl;
                    this.username = data.userNickname;
                    this.auth_link = data.authorizeUrl;
                }
            } else {
                this.checkAuth = 0;
            }
        });
    },
    methods: {
        openAuth() {
            if (!this.agreement) {
                this.$message.error("请阅读并且同意授权协议");
                return;
            }
            const loading = this.$loading({
                lock: true,
                text: "正在获取授权链接",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            getCookiedLin({
                areaCode: this.areaCode,
                authorizedUserId: this.authorizedUserId,
                stateid: this.stateid,
            }).then((res1) => {
                loading.close();
                if (res1.code == 200) {
                    // 获得微微吗
                    var qr = new QrCode();
                    let img = `data:image/png;base64,${res1.data.qrcode}`;

                    // 解析二维码
                    qr.decodeFromImage(img).then((res2) => {
                        // /获得授权链接
                        this.auth_link = res2.data;

                        window.location.href = this.auth_link;
                        // console.log(res2.data);
                    });
                } else {
                    this.$message.error("生成授权链接失败，请重新点击授权");
                }
            });
        },
        openUrl() {
            location.href = this.auth_link;
        },
    },
};
</script>

<style lang="less" scoped>
.error_box,
.success_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}
.error_img {
    width: 100px;
}
.btns {
    display: flex;
    justify-content: center;
}
.mobie-body {
    height: 100%;
    background: linear-gradient(to right, #fff5f7 30%, #d0e7fb);
    // padding: 20px;
    // box-sizing: border-box;
    display: grid;
    place-items: center;
    align-items: center;
    // background: #fff;
    .info_box {
        width: 330px;
        height: 260px;
        padding: 20px;
        background-color: #fff;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .content {
        height: 100%;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        img {
            width: 220px;
        }
    }
}
.agreement-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.agreement_txt {
    margin-left: 4px;
    font-size: 12px;
    color: #8597b2;
    span {
        color: #345582;
        padding: 0 4px;
    }
}
</style>